import React, { Component } from "react"
import Title from "./title"
import styles from "../styles/scss/components/dropdownContent.module.scss"
import cn from "classnames"

export default class Index extends Component {
    state = {
        show: false,
    }

    handleClick() {
        const { onClick } = this.props
        const { show } = this.state
        if (onClick) onClick()
        this.setState({ show: !show })
    }

    render() {
        const { children, title, className } = this.props
        const { show } = this.state
        return (
            <div
                className={
                    show
                        ? cn(styles.openContainer, styles[className])
                        : cn(styles.container, styles[className])
                }
            >
                <div
                    className={styles.header}
                    onClick={this.handleClick.bind(this)}
                    role ="none" onKeyDown={this.handleClick.bind(this)}
                >
                    <Title value={title} className={styles.dropdownTitle}/>
                    <div>
                        <img
                            className={show ? styles.button : ""}
                            src={
                                show
                                    ? "/img/closeButton.svg"
                                    : "/img/plusButton.svg"
                            }
                            alt={show ? "close" : "show"}
                        />
                    </div>
                </div>
                {children && (
                    <div
                        id="collapse"
                        className={
                            show === true ? styles.active : styles.notActive
                        }
                    >
                        {children}
                    </div>
                )}
            </div>
        )
    }
}
