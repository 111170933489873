import React, { Component } from 'react';
import Text from './text';
import styles from '../styles/scss/components/checkboxNew.module.scss';
import cn from 'classnames';

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateValue: this.props.value ? true : false

        }
    }
    
    handleClick(e) {
        const { onClick } = this.props;
        const { stateValue } = this.state;
        this.setState({ stateValue: !stateValue }, () => {
            if (onClick) onClick(this.state.stateValue);
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ 
            stateValue: nextProps.value ? true : false
        });
    }
    render() {
        const { name, text, style, className } = this.props;
        const { stateValue } = this.state;

        return (
            <div className={cn(styles.container, className)} style={style} tabIndex={0} role = "button" onKeyDown={this.handleClick.bind(this)} onClick={this.handleClick.bind(this)}>
                <input id={name} type='checkbox' name={name} checked={stateValue} className={styles.hide} readOnly />
                    <div className={cn(styles.box)} >
                        { stateValue &&                         
                            <div className={cn(styles.active)}>
                                <i className='fa fa-check' />
                            </div>
                        }
                    </div>
                    
                {text && <Text for={name} value={text} className={styles.title} />}
            </div>
        );
    }
}
