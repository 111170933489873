   import React from 'react';
import { map as _map, uniqBy as _uniqBy, sortBy as _sortBy, find as _find, slice as _slice } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Container, Row, Col } from 'react-grid-system';
import {
    useQueryParam,
    withDefault,
    encodeDelimitedArray,
    decodeDelimitedArray,
} from 'use-query-params';

// Componenets
import NewLayout from '../layout/newLayout';
import SelectMany from '../components/selectMany';
import SEO from "../components/seo"
import EventAnchor from '../components/eventAnchor';
import Title from '../components/title';
import CtaBanner from "../components/ctaBanner";
import Card from '../components/card';
import DropdownContent from '../components/dropdownContent';

// Services
import { priceSpiderRebind } from "../services/util";
import { supSub } from '../services/util';

// Styles
import styles from '../styles/scss/pages/products.module.scss'

const filterProducts = function filterProducts(products, selectedFunctionality, selectedCategory) {
    const out = products.filter(product => {
        let matchFunction = true;
        let matchCategory = true;
        if (selectedFunctionality.length) {
            matchFunction = _find(product.productFunctions, func => selectedFunctionality.includes(func.slug)) !== undefined;
        }

        if (selectedCategory && selectedCategory.length) {
            matchCategory = selectedCategory.includes(product.productCategory.slug);
        }

        return matchFunction && matchCategory;
    });

    return out;
};

const pageSize = 12;

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
const CommaArrayParam = {
    encode: (array) =>
        encodeDelimitedArray(array, ','),

    decode: (arrayStr) =>
        decodeDelimitedArray(arrayStr, ',')
};

const NewProductsTemplate = ({
    pageContext,
}) => {
    // Query Params
    const [selectedFunctionality, setSelectedFunctionality] = useQueryParam('functionality', withDefault(CommaArrayParam, []));
    const [selectedCategory, setSelectedCategory] = useQueryParam('category', withDefault(CommaArrayParam, []));

    // States
    const [currentPage, setCurrentPage] = React.useState(0);
    const [products] = React.useState(() => {
        // remove products that should not appear on index
        let initialProducts = (pageContext || []).products.filter(p => !!p.showOnIndex);
        // match and append reviews to products
        if (pageContext.reviews && pageContext.reviews.length > 0) {
            initialProducts.forEach((product, key) => {
                if (product && product.bazaarVoiceId) {
                    const review = pageContext.reviews.find(e => e.id === product.bazaarVoiceId);
                    Object.assign(initialProducts[key], {
                        reviewStats: review,
                    });
                }
            });
        }

        return initialProducts;
    });
    const [mounted, setMounted] = React.useState(false);

    // Effects
    React.useEffect(() => {
        priceSpiderRebind();
    });

    React.useEffect(() => {
        setMounted(true);
    }, []);

    React.useEffect(() => {
        const pricespiderscript = document.createElement("script")
        pricespiderscript.src = "//cdn.pricespider.com/1/lib/ps-widget.js"
        pricespiderscript.async = true
        document.body.appendChild(pricespiderscript)
    }, []);

    // Handlers
    const handleClearAllFilters = React.useCallback(() => {
        setCurrentPage(0);
        setSelectedFunctionality([]);
        setSelectedCategory([]);
    }, []);

    const handleSelectFunctionality = React.useCallback((data) => {
        setCurrentPage(0);
        setSelectedFunctionality(data);
    }, []);

    const handleSelectCategory = React.useCallback((data) => {
        setCurrentPage(0);
        setSelectedCategory(data);
    }, []);

    const handleInfiniteScrollNext = React.useCallback(() => {
        setCurrentPage(s => s + 1);
    }, []);

    // Memos
    const {
        pagedProducts,
        pages,
        totalProducts
    } = React.useMemo(() => {
        const out = {
            pagedProducts: [],
            pages: 0,
            totalProducts: []
        };

        if (products && products.length) {
            // Filter products
            const filteredProducts = filterProducts(products, selectedFunctionality, selectedCategory);

            // Paginate products
            const paginationStart = currentPage * pageSize;
            const paginationEnd = (currentPage * pageSize) + pageSize;
            const newPagedProducts = _slice(filteredProducts, paginationStart, paginationEnd);
            const previousPagedProducts = _slice(filteredProducts, 0, paginationStart);

            out.pagedProducts = currentPage === 0 ? newPagedProducts : [...previousPagedProducts, ...newPagedProducts];
            out.totalProducts = filteredProducts.length;

            // Calculate Pages
            out.pages = Math.ceil(filteredProducts.length / pageSize);
        }

        return out;
    }, [
        currentPage,
        products,
        selectedFunctionality,
        selectedCategory,
        mounted,
    ]);

    const {
        functionalities,
        categories,
    } = React.useMemo(() => {
        const out = {
            functionalities: [],
            categories: [],
        };
        // Get distinct array of product functions, sorted by order and add an option for all
        out.functionalities = _map(_sortBy(_uniqBy([].concat.apply([], _map(products, 'productFunctions')), item => item.id), item => item.order), item => ({ value: item.slug, text: item.name }));
        out.categories = _map(_sortBy(_uniqBy([].concat.apply([], _map(products, 'productCategory')), item => item.id), item => item.order), item => ({ value: item.slug, text: item.name }));

        return out;
    }, [products]);

    const {
        pageSubtitle,
        pageBody,
    } = React.useMemo(() => {
        const pg = pageContext.page;
        const [{ subtitle, body }] = pg.content;

        return {
            pageSubtitle: subtitle ? supSub(subtitle) : null,
            pageBody: body ? supSub(body) : null,
        };
    }, [pageContext]);

    // Disable SSR on this page
    if (typeof window === 'undefined') {
        return null;
    }

    const filteredProductsLengt = functionalities.map(item =>
        filterProducts(products, item.value)
    )

    return (
        <NewLayout>
            {/* SEO */}
            {
                pageContext.page.seo.title && (
                    <h1
                        style={{ display: 'none' }}
                    >
                        {pageContext.page.seo.title}
                    </h1>
                )
            }
            <SEO
                page={pageContext.page}
            />

            {/* Content */}
            <div>
                <div
                    className={styles.container}
                >
                    {/* Top Content */}
                    <div
                        className={styles.topContent}
                    >
                        {
                            pageSubtitle && (
                                <Title
                                    className={styles.sitetitleNewLayout}
                                    value={pageSubtitle}
                                />
                            )
                        }
                        {
                            pageBody && (
                                <div
                                    className={styles.bodyNewLayout}
                                    dangerouslySetInnerHTML={{ __html: pageBody }}
                                />
                            )
                        }
                    </div>

                    {/* Filters */}
                    <div
                        className={styles.selectOptionsNew}>
                        <DropdownContent title="Filter by">
                            <SelectMany
                                name='Functionality'
                                values={functionalities}
                                selected={selectedFunctionality}
                                onChange={handleSelectFunctionality}
                                quantity={filteredProductsLengt}
                                xl={2.4}
                                xxl={2.2} />

                            <EventAnchor
                                text="Clear all filters >>"
                                event={handleClearAllFilters} />
                        </DropdownContent>
                    </div>



                    {/* Infinite Scroll */}
                    <div
                        key={mounted ? 1 : 2}
                    >
                        <InfiniteScroll
                            className={styles.infiniteContainer}
                            dataLength={pagedProducts.length}
                            hasMore={currentPage <= (pages - 1)}
                            loader={() => (
                                <h4>
                                    Loading...
                                </h4>
                            )}
                            endMessage={
                                <p className={styles.endMessage}>
                                    There are no more products
                                </p>
                            }
                        >
                            <div className={styles.showingProducts}>
                                <Title
                                    value={`SHOWING ${totalProducts} PRODUCTS`}
                                    alignLeft
                                />
                            </div>
                            <Container
                                className={styles.containerGridProducts}
                            >
                                <Row
                                    gridcolumns={2}
                                    className={styles.rowGridColumns}
                                >
                                    {
                                        pagedProducts.length > 0 && pagedProducts.map((product, idx) => (
                                            <Col
                                                key={`${product.id}:${idx}`}
                                                className={styles.productCards}
                                                xs={12}
                                                lg={6}
                                            >
                                                <div>
                                                    <Card
                                                        type="ratedProduct"
                                                        product={product}
                                                        title={product.title}
                                                        image={product.image}
                                                        slug={product.slug}
                                                    />
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                {currentPage < (pages - 1) &&
                                    <Row>
                                        <Col
                                            className={styles.seeMoreAnchor}
                                            xs={12}
                                            lg={6}
                                        >
                                            <EventAnchor
                                                text="See more Products >>"
                                                event={handleInfiniteScrollNext}
                                                condensed
                                            />

                                        </Col>

                                    </Row>
                                }

                            </Container>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>

            {/* Bottom Banner */}
            <CtaBanner
                text="WANT TO GET MORE TIPS AND TRICKS?"
                buttonText="SUBSCRIBE TO THE NEWSLETTER"
                buttonUrl="/signup/"
            />
        </NewLayout>
    );
};

export default React.memo(NewProductsTemplate);