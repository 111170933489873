import React, { Component } from 'react';
import Checkbox from './checkboxNew'
import { Container, Row, Col } from 'react-grid-system';
import styles from '../styles/scss/components/selectMany.module.scss';

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.nodeRef = React.createRef();
        this.onClickOption = this.onClickOption.bind(this);
    }

    onClickOption = item => {
        const { value } = item;
        let { onChange, selected } = this.props;

        if (selected.includes(value)) {
            selected = selected.filter((v) => v !== value)
        } else {
            selected = [...selected, value]
        }
        onChange(selected);
    }

    handleClick(e) {
        if (this.nodeRef || this.nodeRef.current == null) {
            return
        }
        if (!this.nodeRef.current.contains(e.target)) this.setState({ show: false });
    }

    render() {
        const { values, name, selected, quantity } = this.props;
        return (
            <Container style={{ margin: 0 }}>
                <Row
                    ref={this.nodeRef}
                    className={styles.rowStyle}
                >
                    {values ? (
                        values.map((v, i) => (
                            <Col key={i} xs={6} sm={6} md={4} lg={4} xl={this.props.xl} xxl={this.props.xxl} className={styles.checkboxItem}>
                                <Checkbox
                                    name={name}
                                    text={quantity ? `${v.text} (${quantity[i].length})` : v.text}
                                    value={selected.includes(v.value)}
                                    onClick={() => this.onClickOption({ id: i, ...v })}
                                />
                            </Col>
                        ))
                    ) : (
                        <p>No Values</p>
                    )}
                </Row>
            </Container>
        );
    }
}
